export enum TxTypes {
  TxTypeEmpty = 0,
  TxTypeChangePubKey = 1,
  TxTypeDeposit = 2,
  TxTypeTransfer = 3,
  TxTypeWithdraw = 4,
  TxTypeCreateOrderBook = 5,
  TxTypeUpdateOrderBook = 6,
  TxTypeCreateOrder = 7,
  TxTypeCancelOrder = 8,
  TxTypeClaimOrder = 9,
  TxTypeFullExit = 10,
  TxTypeFullExitOrder = 11,
}

export enum TxOrderTypes {
  OrderTypeLimit = 0,
  OrderTypeFok = 1,
  OrderTypeIoc = 2,
  OrderTypeMarket = 3,
  OrderPostOnly = 4,
  ClosePositon = 5,
}

export interface UserTransaction {
  txType: TxTypes
  orderType?: TxOrderTypes
  symbol: string
  amount0?: number
  amount1?: number
  price?: number
  isAsk?: boolean
  txHash?: string
  status?: string
}
