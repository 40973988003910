import {
  AAVELogo,
  AMPLLogo,
  BALLogo,
  BATLogo,
  CRVLogo,
  DAILogo,
  ENJLogo,
  ETHLogo,
  GUSDLogo,
  KNCLogo,
  LINKLogo,
  MANALogo,
  MKRLogo,
  RENLogo,
  SNXLogo,
  TUSDLogo,
  UNILogo,
  USDCLogo,
  USDTLogo,
  WBTCLogo,
  WETHLogo,
  WARBLogo,
  WAVAXLogo,
  WOPTLogo,
  WSOLLogo,
  YFILogo,
  ZRXLogo,
  PlaceHolderLogo,
  DOGELogo,
  MATICLogo,
  BTCLogo,
} from 'img/tokens/icons'

export const TOKEN_LOGOS = {
  AAVE: AAVELogo,
  AMPL: AMPLLogo,
  BAL: BALLogo,
  BAT: BATLogo,
  CRV: CRVLogo,
  DAI: DAILogo,
  ENJ: ENJLogo,
  GUSD: GUSDLogo,
  KNC: KNCLogo,
  MANA: MANALogo,
  MKR: MKRLogo,
  REN: RENLogo,
  SNX: SNXLogo,
  TUSD: TUSDLogo,
  UNI: UNILogo,
  USDC: USDCLogo,
  'USDC.e': USDCLogo,
  USDT: USDTLogo,
  WBTC: WBTCLogo,
  BTC: BTCLogo,
  WETH: WETHLogo,
  ETH: ETHLogo,
  WARB: WARBLogo,
  ARB: WARBLogo,
  WAVAX: WAVAXLogo,
  AVAX: WAVAXLogo,
  WOPT: WOPTLogo,
  OPT: WOPTLogo,
  WSOL: WSOLLogo,
  SOL: WSOLLogo,
  WDOGE: DOGELogo,
  DOGE: DOGELogo,
  WLINK: LINKLogo,
  LINK: LINKLogo,
  WMATIC: MATICLogo,
  MATIC: MATICLogo,
  YFI: YFILogo,
  ZRX: ZRXLogo,
  DEFAULT: PlaceHolderLogo,
}

export const TOKEN_DESCRIPTIONS = {
  AAVE: 'Aave (AAVE) is a decentralized finance (DeFi) lending platform that allows users to lend and borrow a diverse range of cryptocurrencies. It operates using a system of pools that enable users to earn interest on deposited assets or borrow assets using deposited collateral, with interest rates determined algorithmically based on supply and demand.',
  AMPL: "Ampleforth (AMPL) is a cryptocurrency with an elastic supply that automatically adjusts in response to demand changes, aiming for price stability. It achieves this by expanding or contracting its supply daily based on the previous day's exchange rate, attempting to maintain a target price of $1.",
  BAL: 'Balancer (BAL) is an automated portfolio manager and decentralized exchange (DEX) built on Ethereum, allowing users to create liquidity pools and earn fees. It operates on the principle of automated market makers (AMMs), enabling users to trade and provide liquidity for various tokens while maintaining the desired weightings of assets in their portfolios.',
  BAT: 'Basic Attention Token (BAT) is a utility token used in the Brave browser ecosystem, rewarding users and creators for engaging with ads. It enables advertisers to pay users directly for their attention, while users can earn BAT by viewing advertisements and publishers can receive BAT as a form of payment for their content.',
  CRV: 'Curve Finance (CRV) is a decentralized exchange (DEX) optimized for stablecoin trading, offering low slippage and low fees. It achieves this by using liquidity pools with low volatility assets, enabling users to exchange stablecoins at close to their market price with minimal trading fees.',
  DAI: 'Dai (DAI) is a stablecoin pegged to the value of the US dollar, maintained by MakerDAO through a system of collateralized debt positions (CDPs). It is generated by users locking up collateral assets, such as Ethereum (ETH), and issuing DAI against them, with the goal of keeping its value stable at $1 USD.',
  ENJ: 'Enjin Coin (ENJ) is a cryptocurrency and blockchain platform focused on gaming, enabling the creation and management of virtual goods. It allows game developers to tokenize in-game assets, such as weapons, characters, or skins, which can be owned, traded, and used across different games and platforms.',
  GUSD: 'Gemini Dollar (GUSD) is a regulated stablecoin backed 1:1 by the US dollar and issued by Gemini, a cryptocurrency exchange. It is designed to provide stability and transparency, with each GUSD token fully backed by US dollars held in FDIC-insured bank accounts.',
  KNC: 'Kyber Network Crystal (KNC) is the native token of Kyber Network, a decentralized liquidity protocol enabling token swaps across different platforms. Holders of KNC can participate in governance decisions, stake KNC to earn fees, and receive rewards for providing liquidity to the network.',
  MANA: 'Decentraland (MANA) is a virtual reality platform built on Ethereum where users can create, own, and monetize digital content and experiences. It uses MANA tokens as the primary currency within its virtual world, allowing users to buy, sell, and trade virtual land, goods, and services.',
  MKR: 'Maker (MKR) is the governance token of the MakerDAO ecosystem, responsible for governing the stablecoin Dai and the Dai Credit System. MKR holders have voting rights to make decisions on protocol changes, including adjustments to the stability fee, collateral types, and other parameters affecting the Dai stablecoin.',
  REN: 'Ren (REN) is an open protocol that enables the transfer of assets between blockchains in a trustless and decentralized manner. It facilitates interoperability by allowing users to mint renBTC, renZEC, and other renTokens, which are Ethereum-based representations of Bitcoin and other assets.',
  SNX: 'Synthetix (SNX) is a decentralized synthetic asset issuance protocol that allows users to mint and trade synthetic assets representing various real-world assets. It operates on the Ethereum blockchain, enabling users to gain exposure to assets such as fiat currencies, commodities, cryptocurrencies, and indices without holding the underlying assets.',
  TUSD: 'TrueUSD (TUSD) is a stablecoin pegged to the US dollar, audited regularly to ensure that the amount of TUSD in circulation is fully backed by USD reserves. It aims to provide a stable and reliable digital dollar, suitable for transactions and trading on cryptocurrency exchanges.',
  UNI: 'Uniswap (UNI) is a decentralized exchange (DEX) and automated liquidity protocol built on Ethereum, facilitating token swaps and liquidity provision. UNI tokens are used for governance, allowing holders to vote on proposals and protocol changes, and also earn a share of trading fees generated by the platform.',
  USDC: 'USD Coin (USDC) is a regulated stablecoin pegged to the US dollar, issued by regulated financial institutions and backed by fully reserved assets. It is designed for seamless integration with the traditional financial system, offering fast and cost-effective transactions for global payments and remittances.',
  USDT: 'Tether (USDT) is a stablecoin pegged to the US dollar and backed 1:1 by fiat reserves, providing stability and liquidity in the cryptocurrency market. It is widely used for trading and as a means of transferring value across different exchanges and platforms due to its price stability.',
  WBTC: 'Wrapped Bitcoin (WBTC) is an ERC-20 token backed 1:1 by Bitcoin, allowing Bitcoin holders to access the Ethereum ecosystem and decentralized finance (DeFi). It enables Bitcoin to be used in Ethereum-based smart contracts, decentralized applications, and for token swaps on decentralized exchanges.',
  BTC: 'Bitcoin (BTC) is the first and most well-known cryptocurrency, created by an anonymous person or group of people using the pseudonym Satoshi Nakamoto. It operates on a decentralized peer-to-peer network, where transactions are recorded on a public ledger called the blockchain, and is often referred to as digital gold.',
  WETH: 'Wrapped Ether (WETH) is an ERC-20 tokenized version of Ether (ETH), enabling ETH to be used in decentralized applications and Ethereum-based smart contracts. It simplifies the process of interacting with ETH on decentralized exchanges and other Ethereum-based protocols by providing a standardized token format.',
  ETH: 'Ethereum (ETH) is a decentralized blockchain platform that enables smart contracts and decentralized applications (DApps) to be built and executed. It is the second-largest cryptocurrency by market capitalization and serves as the foundation for a wide range of projects in the blockchain space.',
  WARB: 'Wrapped ARBITRUM (WARB) is an ERC-20 token representing wrapped Arbitrum tokens, which are used on the Arbitrum layer 2 scaling solution for Ethereum. It allows users to transfer and trade Arbitrum tokens on the Ethereum blockchain, enabling compatibility with Ethereum-based decentralized applications and services.',
  ARB: "Arbitrum (ARB) is a layer 2 scaling solution for Ethereum, designed to increase throughput and reduce transaction costs while maintaining Ethereum's security. It achieves this by using a sidechain that processes transactions off the Ethereum mainnet, with periodic checkpoints to ensure the integrity of the system.",
  WAVAX:
    'Wrapped AVAX (WAVAX) is an ERC-20 token representing wrapped Avalanche tokens, which are used on the Avalanche blockchain platform. It enables AVAX holders to access the Ethereum ecosystem and decentralized finance (DeFi), allowing them to participate in Ethereum-based applications and protocols.',
  AVAX: 'Avalanche (AVAX) is a blockchain platform designed for decentralized applications (DApps) and custom blockchain networks, focusing on scalability and speed. It features a consensus mechanism called Avalanche consensus, which allows for high throughput and quick finality, making it suitable for a wide range of use cases.',
  WOPT: 'Wrapped OPTION (WOPT) is an ERC-20 token representing wrapped Option tokens, which are used for options trading on various decentralized finance (DeFi) platforms. It allows users to buy and sell options contracts on assets such as cryptocurrencies, stocks, and commodities, providing them with exposure to price movements without owning the underlying assets.',
  OPT: 'Option (OPT) is a decentralized finance (DeFi) protocol for options trading, allowing users to buy and sell options contracts on various assets. It provides a permissionless and non-custodial platform for creating, trading, and settling options, enabling users to hedge risk, speculate on price movements, and earn premiums.',
  WSOL: 'Wrapped SOL (WSOL) is an ERC-20 token representing wrapped Solana tokens, which are used on the Solana blockchain platform. It enables SOL holders to access the Ethereum ecosystem and decentralized finance (DeFi), allowing them to participate in Ethereum-based applications and protocols.',
  SOL: 'Solana (SOL) is a high-performance blockchain platform designed for decentralized applications (DApps) and tokenized assets, focusing on scalability and low fees. It features a unique consensus mechanism called Proof of History (PoH), which allows for high throughput and low-latency transactions, making it suitable for applications requiring high-speed and low-cost transactions.',
  WDOGE:
    'Wrapped DOGE (WDOGE) is an ERC-20 token representing wrapped Dogecoin tokens, which are used on the Ethereum blockchain. It enables DOGE holders to access the Ethereum ecosystem and decentralized finance (DeFi), allowing them to participate in Ethereum-based applications and protocols.',
  DOGE: 'Dogecoin (DOGE) is a cryptocurrency featuring a Shiba Inu dog as its logo, originally created as a joke but has since gained a significant following and community. It is known for its active and enthusiastic community, as well as its use in tipping and charitable donations.',
  WLINK:
    'Wrapped Chainlink (WLINK) is an ERC-20 token representing wrapped Chainlink tokens, which are used on the Ethereum blockchain for decentralized oracle services. It enables LINK holders to access the Ethereum ecosystem and decentralized finance (DeFi), allowing them to participate in Ethereum-based applications and protocols.',
  LINK: 'Chainlink (LINK) is a decentralized oracle network that enables smart contracts on various blockchain platforms to securely interact with external data sources. It provides reliable and tamper-proof data feeds for decentralized applications (DApps), enabling them to access real-world information and execute based on that data.',
  WMATIC:
    'Wrapped MATIC (WMATIC) is an ERC-20 token representing wrapped Matic tokens, which are used on the Polygon (formerly Matic) blockchain platform. It enables MATIC holders to access the Ethereum ecosystem and decentralized finance (DeFi), allowing them to participate in Ethereum-based applications and protocols.',
  MATIC:
    "Polygon (formerly Matic) is a layer 2 scaling solution for Ethereum, providing fast and low-cost transactions for decentralized applications (DApps) and assets. It aims to address Ethereum's scalability issues by using sidechains and a variety of scaling techniques, enabling high throughput and interoperability with the Ethereum mainnet.",
  YFI: 'Yearn.finance (YFI) is a decentralized finance (DeFi) platform focused on yield optimization, allowing users to automatically maximize their returns on assets. It aggregates liquidity from various DeFi protocols to optimize yield farming strategies, automatically reallocating funds to achieve the highest yields possible.',
  ZRX: '0x (ZRX) is an open protocol for decentralized exchange (DEX) on the Ethereum blockchain, enabling the peer-to-peer exchange of tokens without the need for intermediaries. It provides infrastructure for building decentralized exchanges, allowing developers to create their own DEXs and liquidity pools.',
}

export const TABLET_WIDTH = 1200

export const MOBILE_WIDTH = 668
export const MOBILE_HEIGHT = 680

export const NAVBAR_HEIGHT = 60

export enum TX_STATUSES {
  StatusFailed,
  StatusPending,
  StatusExecuted,
  StatusPacked,
  StatusCommitted,
  StatusVerified,
}

export const transactionStatuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const SCROLLABLE_CONTAINER_HEIGHT = `calc(100% - 8px)`

export enum OrderDirections {
  Short, // Sell / Ask
  Long, // Buy / Bid
}

export const NUM_ORDERS_TO_SHOW = 20
export const MAX_NUM_TRADES = 100

export const USDC_SYMBOL = 'USDC'
