import { Suspense } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { LoadingSpinner } from './shared-components'

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      lazy: () => import('./Wrapper'),
      children: [
        {
          path: '*',
          lazy: () => import('./pages/not-found'),
        },
        {
          path: '/trade',
          lazy: () => import('./TradesRoute'),
        },
        {
          path: '/portfolio',
          lazy: () => import('./pages/portfolio'),
        },
        {
          path: '/faq',
          lazy: () => import('./pages/faq'),
        },
        // {
        //   path: '/blog/*',
        //   lazy: () => import('./pages/blog'),
        // },
        {
          path: '/404',
          lazy: () => import('./pages/not-found'),
        },
      ],
    },
  ])

  return (
    <Suspense
      fallback={
        <div>
          <LoadingSpinner />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  )
}
export default App
