import { fonts, fontSizes, colors } from 'css/css'
import styled from 'styled-components'

export const PercentageInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${fonts.satoshi};
  color: ${colors.whiteMain};
  font-size: ${fontSizes.size2};
  overflow: hidden;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${colors.whiteOpaque};
    opacity: 0.5;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`
