import styled from 'styled-components/macro'

import { colors } from 'css/css'

import loadingGif from 'img/shared/loading.gif'

const LoadingSpinner = () => (
  <Backdrop>
    <SpinnerContainer>
      <img src={loadingGif} alt={loadingGif} style={{ maxWidth: '4rem' }} />
    </SpinnerContainer>
  </Backdrop>
)

const Backdrop = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.black};
  opacity: 0.4;
`
const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 44px;
`

export default LoadingSpinner
