import { type RefObject, useEffect, useLayoutEffect, useState } from 'react'

import { type UserTransaction } from 'js/types'

import { MOBILE_HEIGHT, MOBILE_WIDTH, TABLET_WIDTH } from '../constants/shared'

import { BigNumber, type ethers } from 'ethers'
import { colors, fontWeights, Text2, underlineAnimation } from 'css/css'
import styled from 'styled-components/macro'
import { TxOrderTypes, TxTypes } from 'js/types/user'

export const copyStringToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('id', 'toBeCopied')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  // handle iOS as a special case
  if (navigator.userAgent.match(/ipad|ipod|iphone/i) != null) {
    copyElementToClipboard('toBeCopied')
    document.body.removeChild(el)
  } else {
    const selection = document.getSelection()
    if (selection != null) {
      const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        selection.removeAllRanges()
        selection.addRange(selected)
      }
    }
  }
}

const copyElementToClipboard = (elementId: string) => {
  const el = document.getElementById(elementId)
  const range = document.createRange()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.contentEditable = true
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.readOnly = false
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
  range.selectNodeContents(el)
  const s = window.getSelection()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.removeAllRanges()
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.addRange(range)
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.
  document.execCommand('copy')
}

export const useWindowSize = () => {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight])
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])
  return size
}

export const getIsMobile = () =>
  window.matchMedia(`screen and (max-width: ${MOBILE_WIDTH}px)`).matches ||
  window.matchMedia(`screen and (max-height: ${MOBILE_HEIGHT}px)`).matches

export const getIsTablet = () =>
  window.matchMedia(`screen and (max-width: ${TABLET_WIDTH}px)`).matches &&
  window.matchMedia(`screen and (min-width: ${MOBILE_WIDTH}px)`).matches

export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, handleClick: () => void) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (ref?.current && !ref.current.contains(e.target as Node)) handleClick()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
}

export const sendTransaction = async ({
  web3,
  transaction,
  txnHashCallback,
}: {
  web3: ethers.providers.JsonRpcSigner
  transaction: Record<string, string | BigNumber>
  txnHashCallback?: (hash: string) => void
}) => {
  try {
    const tx = await web3.sendTransaction({
      ...transaction,
    })
    const hash = tx.hash
    if (txnHashCallback) {
      txnHashCallback(hash)
    }
    const rec = await tx.wait()
    return rec
  } catch {
    return undefined
  }
}

export const isZero = (value: string | undefined) => {
  if (value === undefined) return true
  if (Number(value) === 0) return true
  return false
}

// decorator for making Linkify links open in a new tab
export const linkifyDecorator = (href: string, text: string, key: number) => (
  <StyledLink href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </StyledLink>
)

export const getTransactionCopy = (
  transaction: UserTransaction,
  isPending: boolean | undefined,
) => {
  if (
    transaction == null ||
    (transaction?.txType != TxTypes.TxTypeCreateOrder &&
      transaction?.txType != TxTypes.TxTypeCancelOrder)
  ) {
    return <Text2>Unknown Transaction</Text2>
  }

  if (transaction?.orderType === TxOrderTypes.ClosePositon) {
    return (
      <Text2>
        {isPending ? 'Closing ' : 'Closed '} position for{' '}
        <TokenAmount>
          {transaction.amount0} {transaction.symbol}
        </TokenAmount>
      </Text2>
    )
  }

  if (transaction?.orderType === TxOrderTypes.OrderTypeMarket) {
    return (
      <Text2>
        {isPending ? 'Creating ' : 'Created '} {transaction.isAsk ? 'short' : 'long'} market order
        for{' '}
        <TokenAmount>
          {transaction.amount0} {transaction.symbol}
        </TokenAmount>{' '}
        (${transaction.amount1})
      </Text2>
    )
  }

  const orderTypeToString = (type?: TxOrderTypes) => {
    if (type == null) {
      return ''
    }
    if (type == TxOrderTypes.OrderTypeLimit) {
      return 'limit'
    }
    if (type == TxOrderTypes.OrderTypeIoc) {
      return 'immediate or cancel'
    }
    if (type == TxOrderTypes.OrderTypeFok) {
      return 'fill or kill'
    }
    if (type == TxOrderTypes.OrderPostOnly) {
      return 'post only'
    }
    return ''
  }

  if (
    transaction.isAsk !== undefined &&
    transaction.amount0 !== undefined &&
    transaction.price !== undefined
  ) {
    return (
      <Text2>
        {transaction?.txType == TxTypes.TxTypeCreateOrder
          ? isPending
            ? 'Creating '
            : 'Created '
          : isPending
          ? 'Canceling '
          : 'Canceled '}
        {transaction.isAsk ? 'short ' : 'long '}
        {orderTypeToString(transaction?.orderType)} order for{' '}
        <TokenAmount>
          {transaction.amount0} {transaction.symbol}
        </TokenAmount>{' '}
        at price <TokenAmount>${transaction.price}</TokenAmount>
      </Text2>
    )
  }
  return <Text2>Unknown Transaction</Text2>
}

const TokenAmount = styled.span`
  font-weight: ${fontWeights.bold};
`

const StyledLink = styled.a`
  color: ${colors.primaryBlueMain};
  text-decoration: none;
  ${underlineAnimation}
`
